import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useCustomerIsDistributed, useCustomerIsVayanData } from '../../customers/selectors';
import { useIsAccountant, useIsManager } from '../../relations/selectors';
import UsersLayout from '../../users/component';
import AccountAddress from './account-address';
import AccountBilling from './account-billing';

const Account = (props) => {

    const { url } = useRouteMatch();

    const isAccountant = useIsAccountant();
    const isManager = useIsManager();

    const isDistributed = useCustomerIsDistributed();
    const isVayanData = useCustomerIsVayanData();

    return (
        <Switch>
            {isAccountant && !isVayanData && !isDistributed &&
                <Route path={`${url}/address`} component={AccountAddress} />}
            {isAccountant && !isDistributed && <Route path={`${url}/billing`} component={AccountBilling} />}
            {isManager && <Route path={`${url}/users`} component={UsersLayout} />}
        </Switch>
    );
}

export default Account;
