import React from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, List, ListSubheader, Typography } from "@material-ui/core";
import { useCustomer } from '../customer-provider';
import { companyName } from '../../../companies/component/elements/company-name';
import CreateCompanyByVayandataSidebarButton from '../../../companies/component/create-company-modal/vayandata/create-company-by-vayandata-sidebar-button';
import RenewSubscriptionButton from '../renew-subscriptions/renew-subscriptions-button';
import OfficeDemoRequestSidebarButton from '../../../office-demo-requests/office-demo-requests-sidebar-button';
import CSVImportSidebarButton from '../../../products/component/csv-import/csv-import-sidebar-button';
import { AwaitingValidationCustomersSidebarButton } from '../awaiting-validation/buttons';
import { useTranslation } from 'react-i18next';

export default function CustomerOverviewForVayanData({ }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.for-vayandata' })
    const customer = useCustomer();

    return (
        <ExpansionPanel expanded>
            <ExpansionPanelSummary>
                <Typography>{t('title', { name: companyName(customer) })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {t('actions')}
                            </ListSubheader>
                        }>
                            <CreateCompanyByVayandataSidebarButton vayandata={customer} />
                            <RenewSubscriptionButton />
                            <AwaitingValidationCustomersSidebarButton />
                            <OfficeDemoRequestSidebarButton />
                            <CSVImportSidebarButton />
                        </List>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    )
}
