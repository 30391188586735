import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { useCustomerIsDistributed, useCustomerIsVayanData } from '../../customers/selectors';
import { useTranslation } from 'react-i18next';

function AddressSidebarButton({ onClick, icon, ...props }) {
    const { t } = useTranslation();
    const isVayanData = useCustomerIsVayanData();
    const isDistributed = useCustomerIsDistributed();

    if (isVayanData || isDistributed) return null;

    return (
        <ListItem {...props} button onClick={onClick}>
            {icon && <ListItemIcon><BusinessOutlinedIcon /></ListItemIcon>}
            <ListItemText primary={t('app.account.account-address-sidebar-button.address')} />
        </ListItem >
    )

}

export default AddressSidebarButton;
