import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Chip,
} from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useChangeLocation, useCustomer } from '../customer-provider';
import { useTranslation } from 'react-i18next';

export const useLoadCountAwaitingValidationCustomersCallback = () => {
    const auth = useAuth0();
    const sdk = useSdk();
    const customer = useCustomer();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(null);

    const callback = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setLoading(true);
        dispatch(sdk.customers.countAwaitingValidationForVD(auth, customer.target))
            .then((count) => setCount(count))
            .finally(() => setTimeout(() => setLoading(false), 1000))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    return [count, loading, callback];
}

export const useLoadCountAwaitingValidationCustomersRoutine = () => {

    const [count, loading, callback] = useLoadCountAwaitingValidationCustomersCallback();

    useEffect(() => {
        callback();
        const interval = setInterval(callback, 60 * 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return [count, loading, callback];

}

export const AwaitingValidationCustomersCountBadge = ({ ...props }) => {
    const [count] = useLoadCountAwaitingValidationCustomersRoutine();

    if (count === null) {
        return null;
    } else {
        return (
            <Chip size='small' max={99} label={count} color='primary' />
        )
    }

};

export const AwaitingValidationCustomersSidebarButton = ({ ...props }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.awaiting-validation.buttons' })
    const changeLocation = useChangeLocation();
    const onClick = (ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        changeLocation('customers/awaiting-validation');
    }

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon>
                <WatchLater />
            </ListItemIcon>
            <ListItemText primary={t('sidebar-button-label')} />
            <ListItemSecondaryAction>
                <AwaitingValidationCustomersCountBadge />
            </ListItemSecondaryAction>
        </ListItem >
    );
}
