import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const config = process.env.REACT_APP_TRANSLATION_BUCKET ? {
    order: ['navigator', 'localStorage'],
    caches: ['localStorage'],
    defaultNS: 'translation',
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    backend: {
        loadPath: function (lng) {
            return `${process.env.REACT_APP_TRANSLATION_BUCKET}/${lng}.json`
        },
        crossDomain: true
    }
} : {
    resources: {
        en: require('./translations/en.json'),
    },
    order: ['navigator', 'localStorage'],
    defaultNS: 'translation',
    caches: ['localStorage'],
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(config);
