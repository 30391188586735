import React from 'react';
import { Typography } from '@material-ui/core';
import UserSelect from '../../../users/component/element/user-select';
import { useTranslation } from 'react-i18next';

export default function ChooseUser({ users, user, setUser }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.associate.modal' });
    if (users.length === 0) {
        return (
            <Typography>
                {t('no-users')}
            </Typography>
        )
    } else {
        return <UserSelect value={user} onChange={setUser} users={users} />
    }
}