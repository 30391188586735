import Resource from '../resource';
import _ from 'lodash';
import axios from 'axios';

import fileDownload from 'js-file-download';

export default class DeviceResource extends Resource {

    list(auth, customer, force = false) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const pending = state.allIds.status && state.allIds.status.includes('pending');

            // dont do anything if operation is pending.
            if (!force && pending) {
                return null;
            }

            dispatch(this._makePendingPayload('list', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {}
                });
                dispatch(this._makeSuccessPayload('list', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('list', error, null, flag));
                throw error;
            }
        }
    }

    count(auth, customer) {

        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const pending = state.allIds.status && state.allIds.status === 'count-pending';

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('count', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/count`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('count', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('count', error, null, flag));
                throw error;
            }
        }
    }

    get(auth, identifier, customer, force = false) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = state.byId[identifier];
            const pending = existing && existing.status.endsWith('pending');
            const hasErred = existing && existing.status.endsWith('error');

            // dont do anything if operation is pending or already exists.
            // The update can be forced if its existing
            if ((!force && (existing && !hasErred)) || pending) {
                if (existing && existing.payload) return existing.payload;
                return null;
            }

            dispatch(this._makePendingPayload('get', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {},
                });
                dispatch(this._makeSuccessPayload('get', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('get', error, identifier, flag));
                throw error;
            }
        }
    }

    post(auth, identifier, customer, data) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'post',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                dispatch(this._makeSuccessPayload('post', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('post', error, identifier, flag));
                throw error;
            }
        }
    }

    put(auth, customer, data) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);
            const pending = state.insert.status.includes('pending');

            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('put', null));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    data
                });
                if (res.data.error == 'DeviceAlreadyExists') {
                    throw new Error('Device already declared.');
                }

                dispatch(this._makeSuccessPayload('put', res.data.data, null));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('put', error, null, flag));
                throw error;
            }
        }
    }

    remove(auth, identifier, customer) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);

            const existing = !!state.byId[identifier];
            const pending = existing && state.byId[identifier].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('remove', identifier));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('remove', res.data.data, identifier));
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                dispatch(this._makeErrorPayload('remove', error, identifier, flag));
                throw error;
            }
        }
    }

    license(auth, identifier, filename, customer) {
        return async (dispatch) => {
            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'get',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${identifier}/license`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                fileDownload(JSON.stringify(res.data, null, 4), filename);
                return res.data.data || null;
            } catch (error) {
                const flag = this._catchUnpredictedError(error, dispatch);
                throw error;
            }
        }
    }

    async listOfficeDemoRequestsForCustomer(auth, customer) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `c/${customer}/${this.resource}/office-demo-request/`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }

    createOfficeDemoRequest(auth, deviceId, customer) {
        return async (dispatch, getState) => {

            const state = _.get(getState(), `${this.path}.${this.resource}`);
            const existing = !!state.byId[deviceId];
            const pending = existing && state.byId[deviceId].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', deviceId));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'put',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/${deviceId}/office-demo-request/`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                dispatch(this._makeSuccessPayload('post', res.data.data.device, deviceId));
                return res.data.data || null;
            } catch (error) {
                throw error;
            }
        }
    }

    removeOfficeDemoRequest(auth, deviceId, identifier, customer) {
        return async (dispatch, getState) => {
            const state = _.get(getState(), `${this.path}.${this.resource}`);
            const existing = !!state.byId[deviceId];
            const pending = existing && state.byId[deviceId].status.includes('pending');

            // dont do anything if operation is pending.
            if (pending) {
                return null;
            }

            dispatch(this._makePendingPayload('post', deviceId));

            try {
                const token = await auth.getTokenSilently();
                const res = await axios({
                    method: 'delete',
                    baseURL: this.uri,
                    url: `c/${customer}/${this.resource}/office-demo-request/${identifier}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch(this._makeSuccessPayload('post', res.data.data.device, deviceId));
                return res.data.data || null;
            } catch (error) {
                throw error;
            }
        }
    }

    async countOfficeDemoRequestsForVD(auth, vdId) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `v/${vdId}/${this.resource}/office-demo-request/count`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data;
        } catch (error) {
            throw error;
        }
    }

    async listOfficeDemoRequestsForVD(auth, vdId) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'get',
                baseURL: this.uri,
                url: `v/${vdId}/${this.resource}/office-demo-request/`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }

    async acceptOfficeDemoRequestsForVD(auth, vdId, identifier) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'post',
                baseURL: this.uri,
                url: `v/${vdId}/${this.resource}/office-demo-request/${identifier}/accept`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }

    async rejectOfficeDemoRequestsForVD(auth, vdId, identifier) {
        try {
            const token = await auth.getTokenSilently();
            const res = await axios({
                method: 'delete',
                baseURL: this.uri,
                url: `v/${vdId}/${this.resource}/office-demo-request/${identifier}/reject`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return res.data.data || null;
        } catch (error) {
            throw error;
        }
    }
}