import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import { useCustomerIsDistributed } from '../../customers/selectors';
import { useTranslation } from 'react-i18next';

function BillingSidebarButton({ onClick, icon, ...props }) {

    const { t } = useTranslation();
    const isDistributed = useCustomerIsDistributed();

    if(isDistributed) return null;

    return (
        <ListItem {...props} button onClick={onClick}>
            {icon && <ListItemIcon><CreditCardOutlinedIcon /></ListItemIcon>}
            <ListItemText primary={t('app.account.account-billing-sidebar-button.billing')} />
        </ListItem >
    )

}

export default BillingSidebarButton;
