import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ProductMedia from './product-media';
import ProductDescription from '../element/product-description';
import ProductTitle from '../element/product-title';
import BCardMediaContent from '../../../common/component/card/card-media-content';
import ProductLink from '../element/product-link';

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    description: {
        width: '100%',
    },
}));

function ProductHeader(props) {
    const { product, children } = props;
    const classes = useStyles();

    return (
        <Card>
            <ProductMedia product={product}>
                <BCardMediaContent>
                    <ProductTitle product={product} className={classes.title} gutterBottom variant="h4" />
                    <ProductDescription product={product} className={classes.description} variant="body2" />
                    <ProductLink product={product} />
                </BCardMediaContent>
            </ProductMedia>
        <CardContent>
            {children}
        </CardContent>
        </Card >
    )

}

export default ProductHeader;
