import React, { useMemo } from 'react';
import { Dialog, DialogTitle, Typography } from '@material-ui/core';
import { useProductName } from '../../../products/component/element/product-title';
import stepsGenerator from './steps';
import StepperController from '../../../common/component/stepper-controller';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { useTranslation } from 'react-i18next';

export function MakeOrderController({ component, product, onClose, ...props }) {

    const onComplete = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        onClose();
    };

    props.onComplete = onComplete;

    return (
        <ResourceGetter resource='products' id={product} render={({ product }) =>
            <WaitingLoaded data={product} render={() => React.createElement(component, { product, onClose, ...props })} />
        } />
    )
}

export function MakeOrderModal({ open, ...props }) {

    const { product } = props;

    const steps = useMemo(() => stepsGenerator(product), [product]);
    const productName = useProductName(product)
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal' });

    if (open) {
        return (
            <Dialog fullWidth maxWidth='lg' open={open} onClose={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
            }}>
                <DialogTitle>
                    <Typography>
                        {t('title', { productName })}
                    </Typography>
                </DialogTitle>
                <StepperController  {...props} steps={steps} />
            </Dialog >
        );
    } else {
        return null;
    }

}

export default function MakeOrderFormInDialog(props) {
    return (<MakeOrderController {...props} component={MakeOrderModal} />);
}