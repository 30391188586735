import React, { useCallback, useMemo } from 'react';
import { DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import ReferenceCard from '../../../../references/component/card/reference-card';
import { useTranslation } from 'react-i18next';

export default function CheckSessions(props) {

    const { reference, price, memories, activeStep, previousStep, nextStep, onClose } = props;

    const quantity = useMemo(() => memories[activeStep - 1], [memories, activeStep]);
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.check-sessions' });
    const onNext = useCallback(() => {
        nextStep();
    }, []);

    const onPrevious = useCallback(() => {
        previousStep();
    }, []);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <div>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ReferenceCard price={price} reference={reference} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center'>
                            {t('content', { count: quantity })}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={false} onClick={handleClick(onClose)}>
                    {t('cancel')}
                </Button>
                <Button disabled={false} onClick={handleClick(onPrevious)} color='secondary'>
                    {t('back')}
                </Button>
                <Button disabled={false} variant='outlined' onClick={handleClick(onNext)} color='secondary' >
                    {t('next')}
                </Button>
            </DialogActions>
        </div>
    );
}

export function CheckSessionsLabel() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.check-sessions' });

    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}