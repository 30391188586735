import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import DeviceSumupList from '../../../../order-lines/component/sumup/device-sumup-list';
import { useTranslation } from 'react-i18next';

export default function CheckDevices(props) {

    const { reference, product, memories, activeStep, previousStep, nextStep, onClose } = props;

    const [proceed, setProceed] = useState([]);
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.check-devices' });

    const onNext = useCallback(() => {
        const targets = _.chain(proceed).filter('allow').map('target.target').value();
        nextStep(targets);
    }, [proceed]);

    const onPrevious = useCallback(() => {
        previousStep();
    }, []);

    const onProceed = useCallback((payload) => setProceed(proceed => _.concat(proceed, payload)), [setProceed]);

    const devices = useMemo(() => _.cloneDeep(memories[activeStep - 1]), [memories, activeStep]);
    const nothingToDo = devices && devices.length === 0;
    const allArePossible = _.find(proceed, p => !p.allow) === undefined;
    const noneArePossible = _.find(proceed, p => p.allow) === undefined;
    const cannotProceed = nothingToDo || proceed.length !== devices.length || noneArePossible;

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <Fragment>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DeviceSumupList devices={devices} reference={reference} product={product} onProceed={onProceed} />
                    </Grid>
                    {nothingToDo && (
                        <Grid item xs={12} >
                            <Typography color='error' align='center'>
                                {t('selection-empty')}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={false} onClick={handleClick(onClose)}>
                    {t('cancel')}
                </Button>
                <Button disabled={false} onClick={handleClick(onPrevious)} color='secondary'>
                    {t('back')}
                </Button>
                <Button disabled={cannotProceed} variant='outlined' onClick={handleClick(onNext)} color='secondary' >
                    {noneArePossible
                        ? t('nothing-to-proceed')
                        : !allArePossible ? t('proceed-anyway') : t('next')
                    }
                </Button>
            </DialogActions>
        </Fragment>
    );
}

export function CheckDevicesLabel() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.check-devices' });

    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}