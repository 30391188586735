import React from 'react';
import _ from 'lodash';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useOpenOrdersForCustomerSelector } from '../../../selectors';
import { useCustomer } from '../../../../customers/component/customer-provider';
import OrderCard from '../order-card';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

function OpenOrdersPanel() {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.open-order' })
    const customer = useCustomer();
    const orders = useOpenOrdersForCustomerSelector(customer.target);
    const dispatch = useDispatch();
    const navigate = (to) => dispatch(push('orders/' + to));

    if (orders.length === 0) {
        return (
            <Card >
                <CardContent>
                    <Typography align='center'>
                        {t('no-orders')}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return (
        <Grid container spacing={2}>
            {_.map(orders, (order) => (
                <Grid key={order.target} item xs={12}>
                    <OrderCard order={order} onFinalize={() => {
                        navigate(order.target);
                    }} />
                </Grid>
            ))}
        </Grid>
    )
}

export default OpenOrdersPanel;
