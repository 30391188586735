import ChooseDevices, { ChooseDevicesLabel } from './choose-devices';
import CheckDevices, { CheckDevicesLabel } from './check-devices';
import MakeOrderForDevices, { MakeOrderForDevicesLabel } from './make-order-for-devices';
import { SESSION } from '../../../../products/product-constants';
import SetQuantity, { SetQuantityLabel } from './set-quantity';
import MakeOrderForSessions, { MakeOrderForSessionsLabel } from './make-order-for-sessions';
import CheckSessions, { CheckSessionsLabel } from './check-sessions';

export default function stepGenerator(product) {

    if (product.payload.works_for === SESSION) {
        return [
            {
                label: SetQuantityLabel,
                component: SetQuantity,
            },
            {
                label: CheckSessionsLabel,
                component: CheckSessions,
            },
            {
                label: MakeOrderForSessionsLabel,
                component: MakeOrderForSessions,
            },
        ]
    } else {
        return [
            {
                label: ChooseDevicesLabel,
                component: ChooseDevices,
            },
            {
                label: CheckDevicesLabel,
                component: CheckDevices,
            },
            {
                label: MakeOrderForDevicesLabel,
                component: MakeOrderForDevices,
            },
        ]
    }
}
