import { Grid } from '@material-ui/core';
import React from 'react';
import AvailableDevicesLicences from './available-licences';
import { DeviceOverviewTable } from './device-table';
import { useTranslation } from 'react-i18next';

function DeviceList() {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.list' })

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AvailableDevicesLicences />
            </Grid>
            <Grid item xs={12}>
                <DeviceOverviewTable title={t('title')} />
            </Grid>
        </Grid>

    )

}

export default DeviceList;
