import _, { create } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSdk } from '../../lib/licensing-sdk';
import { useRelationListForCustomer } from '../relations/selectors';

export function makeUserByRelationSelector(sdk) {
    return createSelector(
        sdk.relations.selectors.makePopulateList(),
        sdk.users.selectors.getDb,
        (relations, users) => relations.map(r => {
            if (r && r.payload) {
                r = _.cloneDeep(r);
                r.payload.user = users[r.payload.user];
            }
            return r;
        })
    );
}

export function makeUsersListFromRelations(sdk) {
    return createSelector(
        sdk.relations.selectors.makePopulateList(),
        (relations) => _.chain(relations)
            .filter(relation => relation && relation.payload)
            .map(relation => relation.payload.user)
            .value()
    );
}

export function useUsersListForCustomer() {
    const relations = useRelationListForCustomer();
    return _.map(relations, relation => relation.payload.user);
}