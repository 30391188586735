import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useReferenceById } from '../../../references/selector';
import { useReferenceName } from '../../../references/component/element/reference-name';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import { useCustomer } from '../../../customers/component/customer-provider';
import ActionOk from '../../../common/component/notification/action-ok';
import { useUsersListForCustomer } from '../../../users/selector';
import ChooseUser from './choose-user';
import AssociateActions from './actions';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

function useLoadRelations() {
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const customer = useCustomer();
    const notif = useNotif();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(sdk.helpers.loadRelations(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    return loading;
}

export default function AssociateSessionModal({ open, onClose, session }) {

    const line = session.payload.line;
    const reference = useReferenceById(line.payload.reference);
    const referenceName = useReferenceName(reference);
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.associate.modal' });
    const loading = useLoadRelations();

    const users = useUsersListForCustomer();
    const [user, setUser] = useState(users[0] || null);

    if (open) {
        return (
            <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography display='inline'>{t('title', { referenceName })}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                {t('choose-user')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>{loading ? <Skeleton /> : <ChooseUser user={user} setUser={setUser} users={users} />}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <AssociateActions session={session} user={user} onClose={onClose} />
                </DialogActions>
            </Dialog >
        );
    } else {
        return null;
    }

}
