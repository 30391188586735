import React from 'react';
import OrderLineStatusIcon from '../../../order-lines/component/element/order-line-status-icon';
import { useOrderLineById } from '../../../order-lines/selector';

function SessionOrderStatus({ session, ...props }) {

    const line = useOrderLineById(session.payload.line);

    return (
        <OrderLineStatusIcon {...props} line={line} />
    )
}

export default SessionOrderStatus;
