import React from 'react';

import { RelationsOverviewTable } from '../../relations/component/table';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function UserList(props) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.users.list' })

    return (
        <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary>
                <Typography variant='h4'>
                    {t('title')}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RelationsOverviewTable {...props} />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )

}

export default UserList;
