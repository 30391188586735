import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default function InteractiveLoadingButton({ tooltip, children }) {

    if (tooltip) {
        return (
            <Tooltip title={tooltip} aria-label={tooltip}>
                <span>
                    {children}
                </span>
            </Tooltip>
        )
    } else {
        return children;
    }
}
