import React, { useMemo } from 'react';
import { Dialog, DialogTitle, Typography } from '@material-ui/core';
import StepperController from '../../../../common/component/stepper-controller';
import { getHostId } from '../../../../devices/component/element/device-winid';
import ChooseReferences, { ChooseReferencesLabel } from './choose-references';
import JobScheduler, { OrderJobsLabel } from './order-jobs-queue';

export default function MakeOrderFromDevice({ open, ...props }) {

    const { onClose, device } = props;

    const onComplete = async (event) => {
        event.preventDefault();
        onClose();
    };

    props.onComplete = onComplete;

    const steps = useMemo(() => [
        {
            label: ChooseReferencesLabel,
            component: ChooseReferences,
        },
        {
            label: OrderJobsLabel,
            component: JobScheduler,
        }
    ], []);

    const hostId = getHostId(device);


    if (open) {
        return (
            <Dialog fullWidth maxWidth='lg' disableBackdropClick open={open} onClose={onClose}>
                <DialogTitle>
                    <Typography >Subscribe {hostId} to :</Typography>
                </DialogTitle>
                <StepperController  {...props} steps={steps} />
            </Dialog >
        );
    } else {
        return null;
    }

}
