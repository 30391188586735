import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Drawer, List, Divider, Box, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import DeviceSidebarButton from '../../devices/component/device-sidebar-button';
import SessionSidebarButton from '../../sessions/component/session-sidebar-button';
import AccountSidebarList from '../../account/component/account-sidebar-list';
import OrderSidebarButton from '../../orders/component/order-sidebar-button';
import ProductSidebarButton from '../../products/component/product-sidebar-button';
import { useCompanyIsClient } from '../../companies/selector';
import SidebarCustomerChooser from './sidebar-customer-chooser';
import OverviewSidebarButton from '../../customers/component/overview/overview-sidebar-button';
import { useSdk } from '../../../lib/licensing-sdk';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    versionContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    versionTypo: {
        fontSize: 12,
    }
}));

function SidebarServerVersion() {
    const [version, setVersion] = useState(null);
    const [status, setStatus] = useState('offline');
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.sidebar' });

    const sdk = useSdk();
    useEffect(() => {
        const getHealth = () => {
            sdk.getHealth()
                .then((data) => {
                    const { version } = data;
                    setVersion(version);
                    setStatus('online');
                })
                .catch(e => setStatus('offline'));
        }
        const interval = setInterval(() => getHealth(), 1000 * 20);
        getHealth();
        return () => {
            clearInterval(interval);
        }
    }, []);

    const classes = useStyles();
    return (<Typography
        variant='body2'
        color={status === 'online' ? 'inherit' : 'error'}
        className={classes.versionTypo}>
        {t('server-version', { version })}
    </Typography>)
}

function SidebarAppVersion() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.sidebar' });
    const classes = useStyles();
    return (<Typography variant='body2' className={classes.versionTypo}>
        {t('app-version', { version: process.env.REACT_APP_VERSION })}
    </Typography>)
}

function Sidebar({ underReview }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    let { url } = useRouteMatch();
    const { pathname } = useLocation();

    const exact = (path) => pathname === `${url}/${path}`;
    const contains = (path) => pathname.startsWith(`${url}/${path}`);

    const pushCallback = useCallback((to, ...args) => {
        dispatch(push(`${url}${to ? '/' + to : ''}`, ...args));
    }, []);

    const isCustomer = useCompanyIsClient();

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }


    return (
        <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
            <div className={classes.toolbar} />
            {!isCustomer && (
                <Fragment>
                    <SidebarCustomerChooser />
                    <Divider />
                </Fragment>
            )}
            {!underReview && (
                <>
                    <List>
                        <OverviewSidebarButton selected={pathname == url} onClick={handleClick(() => pushCallback())} />
                    </List>
                    <Divider />

                    <List>
                        <OrderSidebarButton selected={contains('orders')} onClick={handleClick(() => pushCallback(`orders`))} />
                    </List>
                    <Divider />
                    <List>
                        <ProductSidebarButton contains={contains} exact={exact}
                            onClick={(id) => pushCallback(`products/${id}`)} />
                    </List>
                    <Divider />
                    <List>
                        <DeviceSidebarButton selected={contains('devices')} onClick={handleClick(() => pushCallback(`devices`))} />
                        <SessionSidebarButton selected={contains('sessions')} onClick={handleClick(() => pushCallback(`sessions`))} />
                    </List>
                    <Divider />
                    <List>
                        <AccountSidebarList contains={contains} exact={exact} pushCallback={pushCallback} />
                    </List>
                </>
            )}
            <Box flexGrow={1}></Box>
            <Box className={classes.versionContainer}>
                <SidebarAppVersion />
                <SidebarServerVersion />
            </Box>
        </Drawer>
    );
}

export default Sidebar;
