import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { CreateCompanyByDistributorModal } from './create-company-by-distributor';
import { useTranslation } from 'react-i18next';

function CreateCompanyByDistributorSidebarButton({ distributor }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.companies.create-company-modal.distributor.create-company-by-distributor-sidebar-button' });
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick(onOpen)}>
                <ListItemIcon><AddBox /></ListItemIcon>
                <ListItemText primary={t('register')} />
            </ListItem >
            <CreateCompanyByDistributorModal
                distributor={distributor}
                open={open} onClose={handleClick(onClose)} />
        </React.Fragment>
    );
}

export default CreateCompanyByDistributorSidebarButton;