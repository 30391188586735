import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import DeviceSelect from '../../../devices/component/element/device-select';
import { useDispatch } from 'react-redux';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useDeviceListForCustomer } from '../../../devices/selectors';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function useLoadDevices() {
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const customer = useCustomer();

    useEffect(() => {
        setLoading(true);
        dispatch(sdk.helpers.loadDevices(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    return loading;
}

export default function AssociateDevice({ device, setDevice }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.associate.modal' });
    const loading = useLoadDevices();
    const customer = useCustomer();
    const devices = useDeviceListForCustomer(customer.target);

    const value = device || devices[0] && devices[0].target || null;

    useEffect(() => {
        setDevice(value);
    }, [loading]);


    if (devices.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography>
                        {t('no-devices')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    {loading && <CircularProgress />}
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <DeviceSelect onChange={setDevice} value={value} devices={devices} />
                </Grid>
                <Grid item xs={2}>
                    {loading && <CircularProgress />}
                </Grid>
            </Grid>
        )
    }

}
