import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { COEF_POLICY_CUSTOMER, useCoefficient, useCoefficientPolicy, useCustomer } from '../../../../customers/component/customer-provider';
import { makePriceStringWithCurrency } from '../../../../prices/prices-utilities';
import { useMainCompany } from '../../../../relations/component/user-relation-provider';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    head: {
        backgroundColor: theme.palette.primary[theme.palette.type]
    }
}));


export default function OrderTotalTable({ total }) {

    const classes = useStyles();

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-table.total' })
    const coefficient = useCoefficient();
    const coefficientPolicy = useCoefficientPolicy();

    const customer = useCustomer();
    const company = useMainCompany();

    const tax = coefficientPolicy === COEF_POLICY_CUSTOMER ?
        (customer.payload.prefered_tax ? (customer.payload.prefered_tax.percent / 100) : 0) :
        (company.payload.prefered_tax ? (company.payload.prefered_tax.percent / 100) : 0)

    const tax_excluded = t('total-tax-excl-value', makePriceStringWithCurrency(total * coefficient, customer));
    const tax_included = t('total-tax-incl-value', makePriceStringWithCurrency(total * coefficient * (1 + tax), customer));
    const tax_value = t('tax-value', { value: tax * 100 });

    return (
        <Fragment>
            <TableRow>
                <TableCell className={classes.head} colSpan={3}>
                    {t('total-tax-excl')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align='right'>
                    {tax_excluded}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.head} colSpan={3}>
                    {t('tax')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align='right'>
                    {tax_value}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.head} colSpan={3}>
                    {t('total-tax-incl')}
                </TableCell>
                <TableCell className={classes.head} colSpan={3} align='right'>
                    {tax_included}
                </TableCell>
            </TableRow>
        </Fragment>
    )

}
