import React, { useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import Loader from '../../common/component/loader';
import CustomerProvider, { useCustomer } from '../../customers/component/customer-provider';

import Sidebar from './sidebar'
import DevicesLayout from '../../devices/component';
import SessionsLayout from '../../sessions/component';
import UsersLayout from '../../users/component';

import FullScreen from '../../common/component/full-screen';
import Account from '../../account/component';
import OrderLayout from '../../orders/component/layout';
import CustomerLayout from '../../customers/component/customer-layout';
import ProductsLayout from '../../products/component';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GoHomeButton from '../../error/component/go-home-button';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';
import { useLoadReferencesAndProducts } from '../actions';
import CustomerOverview from '../../customers/component/overview';
import OfficeDemoRequestLayout from '../../office-demo-requests';
import { ONBOARDING_COMPLETED } from '../../companies/constants';
import UnderReviewScreen from '../../application/component/under-review-screen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    workbench: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    toolbar: theme.mixins.toolbar,
}));


const useWorkbenchStartingProcedure = (customer, setLoading, setError) => {
    const notif = useNotif();

    const loadReferencesAndProducts = useLoadReferencesAndProducts();

    useEffect(() => {
        setLoading(true);
        loadReferencesAndProducts()
            .catch((err) => {
                setError(true);
                const message = err.response?.data?.message || err.message;
                notif.error(message, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line   
    }, [customer.target]);
}

const WorkbenchInternal = (props) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const customer = useCustomer();
    useWorkbenchStartingProcedure(customer, setLoading, setError);
    const { t } = useTranslation('translation', { keyPrefix: 'app.workbench.starting' });
    const { url } = useRouteMatch();

    if (error) {
        return (
            <CenteredFullScreen>
                <Typography>
                    {t('error')}
                </Typography>
                <GoHomeButton variant='outlined'></GoHomeButton>
            </CenteredFullScreen>
        );
    }

    if (loading) {
        return (
            <CenteredFullScreen>
                <Loader message={t('loading', { customerName: customer.payload.name })} />
            </CenteredFullScreen>
        );
    }

    const underReview = customer?.payload?.onboarding_status !== ONBOARDING_COMPLETED

    return (
        <FullScreen>
            <Sidebar underReview={underReview} />
            <div className={classes.workbench}>
                <div className={classes.toolbar}></div>
                <div className={classes.content}>
                    {underReview ? (<UnderReviewScreen />) : (
                        <Switch>
                            <Route path={`${url}/account`} component={Account} />
                            <Route path={`${url}/orders`} component={OrderLayout} />
                            <Route path={`${url}/customers`} component={CustomerLayout} />
                            <Route path={`${url}/products`} component={ProductsLayout} />
                            <Route path={`${url}/devices`} component={DevicesLayout} />
                            <Route path={`${url}/office-demo-requests`} component={OfficeDemoRequestLayout} />
                            <Route path={`${url}/sessions`} component={SessionsLayout} />
                            <Route path={`${url}/users`} component={UsersLayout} />
                            <Route path={`${url}`} exact component={CustomerOverview} />
                        </Switch>
                    )}
                </div>
            </div>
        </FullScreen>
    );
}

const Workbench = (props) => {
    return (
        <CustomerProvider>
            <WorkbenchInternal {...props} />
        </CustomerProvider>
    )
}

export default Workbench;