import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useOrderById } from '../../../selectors';
import OrderCard from '../order-card';

function OrderDetails(props) {

    const { orderId } = useParams();
    const order = useOrderById(orderId);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OrderCard order={order} />
            </Grid>
        </Grid>
    )
}

export default OrderDetails;
