import React, { useMemo } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader } from '@material-ui/core';
import _ from 'lodash';
import { useListOrderLinesForDevice } from '../../../order-lines/selector';
import { useReferenceById } from '../../../references/selector';
import { useReferenceName as getReferenceName } from '../../../references/component/element/reference-name';
import { useProductName } from '../../../products/component/element/product-title';
import { useFindProductById } from '../../../products/selector';
import OrderLineStatusIcon from '../../../order-lines/component/element/order-line-status-icon';
import CreateRequestButton from '../../../office-demo-requests/actions/customer/create-request';
import DeleteRequestButton from '../../../office-demo-requests/actions/customer/delete-request';
import { orderLinePeriodEndString } from '../../../order-lines/component/element/order-line-period-end';
import { useTranslation } from 'react-i18next';

function DeviceTableLineItem({ line }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.table.details-panel' })
    const reference = useReferenceById(line.payload.reference);
    if (reference === undefined) {
        return null;
    }
    const referenceName = getReferenceName(reference);

    return (
        <ListItem>
            <ListItemIcon>
                <OrderLineStatusIcon line={line} />
            </ListItemIcon>
            <ListItemText primary={referenceName} secondary={line.payload?.period.end ?
                <>{t('expiration-date', { date: orderLinePeriodEndString(line) })}</>
                : null} />
        </ListItem>
    )
}

function DeviceTableProductGroup({ product, lines, ...props }) {

    product = useFindProductById(product);
    const productName = useProductName(product);

    return (
        <Grid item xs={12} md={4}>
            <List subheader={<ListSubheader>{productName}</ListSubheader>} dense >
                {_.map(lines, line => <DeviceTableLineItem key={line.target} line={line} {...props} />)}
            </List>
        </Grid>
    )
}

export default function DeviceTablePanel({ device, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.table.details-panel' })
    const lines = useListOrderLinesForDevice(device);
    const byProduct = useMemo(() => _.groupBy(lines, 'payload.product'), [lines]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} >
                {_.map(byProduct, (lines, product) =>
                    <DeviceTableProductGroup key={product} product={product} lines={lines} {...props} />
                )}
            </Grid>
            <Grid item xs={12}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={device.payload.office_demo ? t('office-demo') : t('production-device')}
                            secondary={device.payload.office_demo_request ? t('office-demo-requested') : ''} />
                        <ListItemSecondaryAction>
                            <CreateRequestButton device={device} />
                            <DeleteRequestButton device={device} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

            </Grid>
        </Grid>
    )
}
