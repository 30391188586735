import React from 'react';
import {
    CircularProgress,
    IconButton,
    ListItem, ListItemIcon, ListItemText, makeStyles, Tooltip,
} from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { useCustomerIsDistributor } from '../../selectors';
import { useCustomer } from '../customer-provider';
import { CustomersOrdersCountBadge, useLoadCustomersOrdersRoutine } from '../customer-orders';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    iconButton: {
        padding: 'unset',
    },
    padded: {
        marginLeft: theme.spacing(3),
    }
}));

const OverviewSidebarButtonForDistributor = ({ onClick, ...props }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.sidebar-button' })
    const classes = useStyles();
    const buyer = useCustomer();
    const [loading, refresh] = useLoadCustomersOrdersRoutine(buyer);

    return (
        <ListItem {...props} button onClick={onClick}>
            {loading
                ? (<CircularProgress size={24} />)
                : (
                    <Tooltip title={t('tooltip')}>
                        <IconButton className={classes.iconButton} onClick={refresh}>
                            <ViewModuleIcon />
                        </IconButton>
                    </Tooltip>
                )}
            <ListItemText className={classes.padded} primary={t('label')} />
            <CustomersOrdersCountBadge buyer={buyer} />
        </ListItem >
    );
}

const OverviewSidebarButtonForOthers = ({ onClick, ...props }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.customers.overview.sidebar-button' })
    
    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon><ViewModuleIcon /></ListItemIcon>
            <ListItemText primary={t('label')} />
        </ListItem >
    );
}

const OverviewSidebarButton = ({ onClick, ...props }) => {

    const isDistributor = useCustomerIsDistributor();

    if (isDistributor) {
        return <OverviewSidebarButtonForDistributor onClick={onClick} {...props} />
    } else {
        return <OverviewSidebarButtonForOthers onClick={onClick} {...props} />
    }
}

export default OverviewSidebarButton;