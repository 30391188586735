import React from 'react';
import { Grid } from '@material-ui/core';
import CompanyBillingFormPanel from '../../companies/component/forms/billing-form/controllers/change';
import CompanyPaymentMethodFormPanel from '../../companies/component/forms/payment-method-form';
import InvoicesListInPanel from '../../invoices/component';
import SubscriptionsListInPanel from '../../subscriptions/components';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SubscriptionPage from '../../subscriptions/components/subscription-page';
import { useCustomerIsVayanData } from '../../customers/selectors';

const AccountBillingIndex = () => {
    const isVayanData = useCustomerIsVayanData();

    return (
        <Grid container spacing={2}>
            {!isVayanData && <Grid item xs={12}>
                <CompanyBillingFormPanel defaultExpanded />
            </Grid>}
            {!isVayanData && <Grid item xs={12}>
                <CompanyPaymentMethodFormPanel defaultExpanded />
            </Grid>}
            <Grid item xs={12}>
                <SubscriptionsListInPanel defaultExpanded />
            </Grid>
            {!isVayanData && <Grid item xs={12}>
                <InvoicesListInPanel defaultExpanded />
            </Grid>}
        </Grid>
    );

}

const AccountBilling = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}`} exact component={AccountBillingIndex} />
            <Route path={`${url}/subscriptions/:subid`} component={SubscriptionPage} />
            <Route path={'*'} render={() => <Redirect to={url} />} />
        </Switch>
    )
}

export default AccountBilling;