import React from 'react';
import OrderLineReferenceName from '../../../order-lines/component/element/order-line-reference-name';
import { useOrderLineById } from '../../../order-lines/selector';

function SessionReferenceName({ session, ...props }) {
    const line = useOrderLineById(session.payload.line);
    return (
        <OrderLineReferenceName {...props} line={line} />
    )
}

export default SessionReferenceName;
