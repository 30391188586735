import React, { Fragment, useMemo, useState } from 'react';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import _ from 'lodash';
import {
    Star as ServiceIcon,
    SettingsOutlined as SettingsIcon
} from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/styles';
import { useProductListForCustomer } from '../selector';
import WaitingLoaded from '../../common/component/waiting-loaded';
import { useIsViewingMainCustomer } from '../../customers/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(2),
        borderLeft: depth => depth > 0 ? 'solid grey 1px' : null
    },
}));

export const ProductSidebarItem = ({ data: { name, product }, depth, contains, onClick }) => {

    const classes = useStyles(depth);

    const handleClick = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        onClick(`view/${product.target}`);
    }

    return (
        <ListItem button selected={contains(`products/view/${product.target}`)}
            className={classes.nested} onClick={handleClick}>
            <WaitingLoaded data={product} render={() => (
                <ListItemText primary={name} />
            )} />
        </ListItem >
    )
}

export const ProductSidebarList = ({ data: { name, data }, depth, contains, ...props }) => {

    const classes = useStyles(depth);

    const defaultOpen = useMemo(() => {
        const reducer = (data) => _.reduce(data,
            (open, node) =>
                open
                || (node.type === 'item' && contains(`products/view/${node.product.target}`))
                || (node.type === 'list' && reducer(node.data))
            , false)
        return reducer(data);
        // eslint-disable-next-line
    }, [contains]);

    const [open, setOpen] = useState(defaultOpen);


    const handleClick = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(o => !o);
    }

    return (
        <Fragment>
            <ListItem button onClick={handleClick} className={classes.nested}>
                <ListItemText primary={name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.nested}>
                <List dense component="div">
                    {data.map(node => <ProductSidebarNode key={node.name} {...props} contains={contains} node={node} depth={depth + 1} />)}
                </List>
            </Collapse>
        </Fragment>
    )
}

export const ProductSidebarNode = ({ node: { type, ...data }, ...props }) => {

    if (type === 'list') {
        return <ProductSidebarList {...props} data={data} />
    } else {
        return <ProductSidebarItem {...props} data={data} />
    }

}

export default function ProductSidebarButton({ ...props }) {

    const isMainView = useIsViewingMainCustomer();
    const products = useProductListForCustomer();
    const { t } = useTranslation('translation', { keyPrefix: 'app.products.sidebar-button' });

    const data = useMemo(() => {
        const data = [];
        _.chain(products)
            .cloneDeep()
            .sortBy('payload.index')
            .each(product => {
                product.keys = product.payload.name.split('/');

                const iterate = (product, data) => {
                    let [key] = product.keys.splice(0, 1);
                    key = key.trim();
                    let node = _.find(data, ({ name, type }) => name === key && type === 'list');

                    if (product.keys.length > 0) {
                        if (!node) {
                            node = {
                                type: 'list',
                                name: key,
                                data: []
                            }
                            data.push(node);
                        }

                        iterate(product, node.data);
                    } else {
                        if (node) {
                            node.data.push({
                                type: 'item',
                                name: key,
                                product,
                            })
                        } else {
                            data.push({
                                type: 'item',
                                name: key,
                                product,
                            })
                        }

                    }
                }

                iterate(product, data);
            })
            .value();

        return data;
    }, [products]);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <Fragment>
            <ListItem>
                <ListItemIcon><ServiceIcon /></ListItemIcon>
                <ListItemText primary={t('label')} />
                {!isMainView &&
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="add" onClick={handleClick(() => props.onClick(`configure`))}>
                            <SettingsIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>
            <List dense component="div">
                {data.map(node => <ProductSidebarNode key={node.name} {...props} node={node} depth={0} />)}
            </List>
        </Fragment >
    )

}
