import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import OpenOrdersPanel from '../details/open-order/open-orders-panel';
import OrderHistoryPanel from '../details/order-history/order-history-panel';

function OrderOverview({ push }) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpenOrdersPanel />
            </Grid>
            <Grid item xs={12}>
                <OrderHistoryPanel push={push} />
            </Grid>
        </Grid>
    )
}

export default OrderOverview;
