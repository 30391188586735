import React from 'react';
import ProductHeader from './product-header';
import ProductContent from './product-content';
import ProductContentLoading from './product-content-loading';

export default function ProductOverview({ product }) {

    return (
        <ProductHeader product={product.target}>
            {product.payload
                ? <ProductContent product={product.target} />
                : <ProductContentLoading />
            }
        </ProductHeader>
    )

}
