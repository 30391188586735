import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import AssociateSessionModal from '../associate-modal';
import { useIsManager } from '../../../relations/selectors';
import { useTranslation } from 'react-i18next';

export function AssociateButtonView({ session, ...props }) {

    const [open, setOpen] = useState(false);
    const openDialog = useCallback(() => setOpen(true), []);
    const closeDialog = useCallback(() => setOpen(false), []);
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.actions.associate' });
    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <React.Fragment>
            <Button {...props} onClick={handleClick(openDialog)} color='secondary'>{t('button-label')}</Button>
            <AssociateSessionModal session={session} open={open} onClose={handleClick(closeDialog)} />
        </React.Fragment>
    )
}

export default function AssociateButton({ session, ...props }) {

    const isManager = useIsManager();
    if (!isManager) {
        return null;
    }

    return <AssociateButtonView {...props} session={session} />
}

