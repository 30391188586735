import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { People as UserIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

function UsersSidebarButton({ onClick, icon, ...props }) {

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    const { t } = useTranslation('translation', { keyPrefix: 'app.users.sidebar-button' });


    return (
        <ListItem {...props} button onClick={handleClick(onClick)}>
            {icon && <ListItemIcon><UserIcon /></ListItemIcon>}
            <ListItemText primary={t('label')} />
        </ListItem >
    )

}

export default UsersSidebarButton;
