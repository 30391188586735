import React, { useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { DialogContent, DialogActions, Button, Typography, ListSubheader, List, Grid, Divider } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useSdk } from '../../../../../lib/licensing-sdk';
import { useSelector } from 'react-redux';
import ReferenceSelectListItem from './reference-select-list-item';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useByProductPriceListForDevices } from '../../../../prices/selector';
import { useFindProductById } from '../../../../products/selector';
import ProductTitle from '../../../../products/component/element/product-title';
import { useTranslation } from 'react-i18next';

function useOrderLinesForDeviceOrderedByProduct(device) {
    const sdk = useSdk();

    const selector = useMemo(() => createSelector(
        sdk.orderlines.selectors.getDb,
        (orderlines) => {
            const list = device.payload ? device.payload.lines : [];
            return _.chain(list)
                .map(id => orderlines[id])
                .filter(o => o && o.payload)
                .groupBy('payload.product')
                .value();
        }
    ), [device]);
    return useSelector(selector);
}

function PricesByProductForDevices({ onSelect, selected, product, prices, existingOrders }) {

    product = useFindProductById(product);

    const canAcceptSameReference = product.payload.rules.multiple_same_reference_allowed;
    const canAcceptMultipleOrders = product.payload.rules.multiple_orders_allowed;
    const productAlreadyOrdered = !!_.find(selected, s => s.count > 0) || (!!existingOrders && existingOrders.length > 0);

    const ordersByPrice = _.groupBy(existingOrders, 'payload.price')

    const handleSelect = (price, count) => {
        onSelect(product.target, price, count);
    }

    return (
        <List subheader={<ListSubheader><ProductTitle product={product.target} /></ListSubheader>}>
            {_.map(prices, price => {

                const selectedItem = selected[price.target] || { count: 0, price: price.target };
                const isSelected = selectedItem.count > 0;
                const priceAlreadyOrdered = isSelected && ordersByPrice[price.target] && ordersByPrice[price.target].length > 0;
                const canOrderMore = (canAcceptMultipleOrders && isSelected && (canAcceptSameReference && priceAlreadyOrdered));

                // The plan can be subscribed multiple if service allow multiple orders and multiple orders for same plan.
                const allowMultiple = canAcceptMultipleOrders && canAcceptSameReference;

                // cannot add if multiple orders for same service are not granted and one order is already made
                // or if multiple orders for same plan is not granted and one order is already made for that plan.
                const canAdd = !((!canAcceptMultipleOrders && productAlreadyOrdered) || (!allowMultiple && priceAlreadyOrdered));

                // Can remove an item if already selected only.
                const canRemove = isSelected;

                return (
                    <ReferenceSelectListItem key={price.target}
                        onSelect={handleSelect} selected={isSelected} multiple={allowMultiple} count={selectedItem.count}
                        price={price.target} canRemove={canRemove} canAdd={canAdd} />
                )
            })}
            <Divider />
        </List>
    )
}

export function GroupedPricesListForDevices({ device, onSelect, selected }) {

    const customer = useCustomer();
    const byProduct = useByProductPriceListForDevices(customer);
    const lines = useOrderLinesForDeviceOrderedByProduct(device);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {_.map(byProduct, (prices, product) => (
                    <PricesByProductForDevices key={product} product={product} prices={prices}
                        onSelect={onSelect} selected={selected[product] || {}} existingOrders={lines[product]} />
                ))}
            </Grid>
        </Grid>
    )
}



export default function ChooseReferences(props) {

    const { device, memory, nextStep, onClose } = props;

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.choose-references' })
    const [prices, setPrices] = useState(memory || {});

    const onNext = useCallback((ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        nextStep(prices);
    }, [prices]);

    const onSelect = useCallback((product, price, count) => {
        let cloned = _.cloneDeep(prices);
        let dico = cloned[product];
        if (!dico) cloned[product] = dico = {};
        dico[price] = { price, count };
        if (count == 0) delete dico[product];
        if (_.size(dico) == 0) delete cloned[product];
        setPrices(cloned);
    }, [prices]);

    return (
        <React.Fragment>
            <DialogContent>
                <GroupedPricesListForDevices device={device} onSelect={onSelect} selected={prices} />
            </DialogContent>
            <DialogActions>
                <Button disabled={false} onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button disabled={false} onClick={onNext} color='secondary' >
                    {t('next')}
                </Button>
            </DialogActions>
        </React.Fragment>
    );
}

export function ChooseReferencesLabel({ service }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.choose-references' })

    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}