import React from 'react';
import _ from 'lodash';
import { ListItem, ListItemText, ListItemIcon, Checkbox, ListItemSecondaryAction, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PriceReferenceName from '../../../../prices/component/element/price-reference-name';

export default function ReferenceSelectListItem({ onSelect, count, multiple, canAdd, canRemove, price }) {

    const checked = count > 0
    const disabled = (checked && !canRemove) || (!checked && !canAdd);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <ListItem disabled={disabled}>
            <ListItemIcon>
                <Checkbox
                    onClick={handleClick(() => onSelect(price, count > 0 ? 0 : 1))}
                    edge="start"
                    disabled={disabled}
                    checked={checked}
                />
            </ListItemIcon>
            <ListItemText primary={<PriceReferenceName price={price} />} />
            <ListItemSecondaryAction>
                {(multiple || count == 1) &&
                    <IconButton disabled={!canRemove} aria-label="remove" onClick={handleClick(() => onSelect(price, count - 1))}>
                        <RemoveIcon />
                    </IconButton>
                }
                {multiple &&
                    <Typography display='inline'>{count}</Typography>
                }
                {(multiple || count == 0) &&
                    <IconButton disabled={!canAdd} aria-label="add" onClick={handleClick(() => onSelect(price, count + 1))}>
                        <AddIcon />
                    </IconButton>
                }
            </ListItemSecondaryAction>
        </ListItem>

    )
}
