
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CardContent, Card, CardHeader, CardActions, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { orderDate, orderTime } from '../element/order-created-at';
import OrderTable from './table/order-table';
import OrderActions from './orders-actions';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import OrderStatus from '../element/order-status';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: '100%',
    },
    statusBadge: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardActions: {
        '& > :not(:first-child)': {
            marginLeft: 'initial'
        },
        '& > :not(:last-child)': {
            borderRight: `1px solid grey`
        },
        justifyContent: 'flex-end',
    }
}));

function useLoadLines(order) {
    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all(order.payload.lines.map(line =>
            dispatch(sdk.orderlines.get(auth, line, order.payload.customer, true))
        ))
            .finally(() => setTimeout(() => setLoading(false), 1000))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, [order.target]);

    return [loading];
}

function OrderCardLoading() {
    const classes = useStyles();

    return (
        <Card className={classes.fullwidth} >
            <CardHeader
                title={<Skeleton />}
            />
            <CardContent>
                <Skeleton height={300} />
            </CardContent>
            <CardActions>
                <Skeleton />
            </CardActions>
        </Card>
    )
}


function OrderCard({ order, onFinalize }) {
    const classes = useStyles();

    const [loading] = useLoadLines(order);

    if (!order.payload || loading) {
        return <OrderCardLoading />
    }

    return (
        <Card className={classes.fullwidth} >
            <CardHeader
                title={
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {`${orderDate(order)} ${orderTime(order)}`}
                        </Grid>
                        <Grid className={classes.statusBadge} item xs={6}>
                            <OrderStatus variant='default' order={order} />
                        </Grid>

                    </Grid>
                }
            />
            <CardContent>
                <OrderTable order={order} />
            </CardContent>
            <CardActions className={classes.cardActions}>
                <OrderActions order={order} onFinalize={onFinalize} />
            </CardActions>
        </Card>
    )
}

export default OrderCard;
