import React from 'react';
import {
    Chip,
    ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { useOpenOrdersCount } from '../selectors';
import { useTranslation } from 'react-i18next';

const OrderSidebarButton = ({ onClick, ...props }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.sidebar-button' })
    const openOrdersCount = useOpenOrdersCount();

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon><ShoppingCart /></ListItemIcon>
            <ListItemText primary={t('label')} />

            {openOrdersCount > 0 && <ListItemSecondaryAction>
                <Chip size='small' max={99} label={openOrdersCount} color='primary' />
            </ListItemSecondaryAction>}
        </ListItem >
    );
}

export default OrderSidebarButton;