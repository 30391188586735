import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import ReferenceName from '../../../references/component/element/reference-name';

function PriceReferenceName({ price, ...props }) {

    return (
        <ResourceGetter resource='prices' id={price} render={({ price }) =>
            <WaitingLoaded data={price} render={() => <ReferenceName {...props} reference={price.payload.reference} />} />
        } />
    )
}

export default PriceReferenceName;
