
import React from 'react';
import {
    ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { useChangeLocation } from '../customers/component/customer-provider';
import { OfficeDemoRequestsCountBadge } from './office-demo-requests-badge';
import { useTranslation } from 'react-i18next';

const OfficeDemoRequestSidebarButton = ({ ...props }) => {
    const changeLocation = useChangeLocation();
    const { t } = useTranslation('translation', { keyPrefix: 'app.office-demo-requests.list' })

    const onClick = (ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        changeLocation('office-demo-requests/');
    }

    return (
        <ListItem {...props} button onClick={onClick}>
            <ListItemIcon><ShoppingCart /></ListItemIcon>
            <ListItemText primary={t('sidebar-button')} />
            <OfficeDemoRequestsCountBadge />
        </ListItem >
    );
}

export default OfficeDemoRequestSidebarButton;