import React from 'react';
import { DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import Loader from '../../../../common/component/loader';
import { useChangeLocation } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

function OrderCompleteView(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography align='center'>
                    {t('completed')}
                </Typography>
            </Grid>
        </React.Fragment>
    )
}

function OrderErrorView(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography align='center'>
                    {t('error')}
                </Typography>
            </Grid>
        </React.Fragment>
    )
}

function OrderProcessingView(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <Grid item xs={12}>
            <Loader message={t('processing')} />
        </Grid>
    )
}

export function ContinuePurchasesButton({ onClose, ...props }) {
    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <Button variant='outlined' onClick={handleClick(onClose)} {...props} >
            {t('continue-purchases')}
        </Button>
    )
}

export function ViewCartButton({ changeLocation, ...props }) {
    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    return (
        <Button variant='outlined' onClick={handleClick(() => changeLocation('orders'))} color='secondary' {...props}>
            {t('view-cart')}
        </Button>
    )
}

export default function OrderFinishingView({ done, error, ...props }) {

    const { onClose, previousStep } = props;
    const changeLocation = useChangeLocation();
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.done' })

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <div>
            <DialogContent>
                <Grid container spacing={2}>
                    {done
                        ? error
                            ? <OrderErrorView onClose={onClose} />
                            : <OrderCompleteView onClose={onClose} />
                        : <OrderProcessingView />
                    }
                </Grid>
            </DialogContent>
            {done &&
                (<DialogActions>
                    {error && <Button variant='outlined' onClick={handleClick(previousStep)} color='secondary' >
                        {t('back')}
                    </Button>}
                    <ContinuePurchasesButton onClose={onClose} />
                    <ViewCartButton changeLocation={changeLocation} />
                </DialogActions>)
            }
        </div>
    );
}
