import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import CancelIcon from '@material-ui/icons/Cancel';
import AlarmIcon from '@material-ui/icons/AccessAlarm';
import { STATUS_ACCEPTED_BY_ADMIN, STATUS_ACCEPTED_BY_DISTRIBUTOR, STATUS_AWAITING_PAYMENT, STATUS_CANCELLED, STATUS_IDLE, STATUS_PAID } from '../../constants';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { RECURRING } from '../../../prices/price-constants';
import { useCurrentRelation } from '../../../relations/component/user-relation-provider';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useIsAccountant, useIsManager } from '../../../relations/selectors';

const useStyles = makeStyles(theme => ({
    green: {
        color: theme.palette.success.main,
    },
    orange: {
        color: theme.palette.warning.main,
    },
    red: {
        color: theme.palette.error.main,
    }
}));

function Icon({ line, ...props }) {
    const classes = useStyles();
    const valid = [STATUS_PAID, STATUS_ACCEPTED_BY_DISTRIBUTOR, STATUS_ACCEPTED_BY_ADMIN, STATUS_CANCELLED].includes(line.payload.status);
    const waiting = [STATUS_IDLE, STATUS_AWAITING_PAYMENT].includes(line.payload.status);
    const expired = line.payload.type === RECURRING && new Date(line.payload.period.end) < new Date();

    if (valid) {
        if (expired) {
            return <AlarmIcon className={classes.orange} />
        } else {
            return <CheckCircleIcon className={classes.green} />
        }
    }
    else if (waiting) return <PauseCircleFilledIcon className={classes.orange} />
    else return <CancelIcon className={classes.red} />
};

function OrderLineStatusIcon({ line, ...props }) {

    const status = line.payload.status.replace(/[_-]/g, ' ');
    const recurring = line.payload.type === RECURRING;

    const dispatch = useDispatch();
    const relation = useCurrentRelation();
    const customer = useCustomer();

    const hasRights = useIsAccountant() | useIsManager();

    const onClick = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        dispatch(push(`/l/r/${relation.target}/c/${customer.target}/account/billing/subscriptions/${line.target}`));
    };

    const Wrapped = React.forwardRef(({ children, ...props }, ref) => {
        if (recurring && hasRights) {
            return (
                <IconButton ref={ref} {...props} size='small' onClick={onClick}>
                    {children}
                </IconButton>
            );
        } else {
            return (
                <div ref={ref} {...props} >
                    {children}
                </div>
            );
        }
    });

    return (
        <Tooltip title={status}>
            <Wrapped>
                <Icon {...props} line={line} />
            </Wrapped>
        </Tooltip>
    )
}

export default OrderLineStatusIcon;
