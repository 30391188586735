import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import DeviceWinid from '../element/device-winid';
import DeviceWinidEditable, { WINID_REGEX } from '../element/device-winid-editable';
import DeviceLabelEditable from '../element/device-label-editable';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import DeviceLastUpdate from '../element/device-last-update';
import DeviceLabel from '../element/device-label';
import { TableController } from '../../../common/component/table/table-controller';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import DetailPanel from '../../../common/component/table/detail-panel';
import DownloadLicenseButton from '../actions/download-license';
import SubscribeDeviceButton from '../actions/subscribe';
import DeviceSubscriptionsCount from '../element/device-subscriptions-count';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useDeviceListForCustomer } from '../../selectors';
import DeviceTablePanel from './device-table-panel';
import DeviceOfficeDemoCheckbox from '../element/device-office-demo-checkbox';
import { useTranslation } from 'react-i18next';

export function DeviceTableController(props) {

    const { add, edit, remove, exports, refresh, pageSize = 8, ...more } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.table' })
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const customer = useCustomer();

    const devices = useDeviceListForCustomer(customer.target);
    const data = useMemo(() => _.cloneDeep(_.compact(devices)), [devices]);

    useEffect(() => {
        setLoading(true);
        dispatch(sdk.helpers.loadDevices(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    const columns = useMemo(() => [
        {
            title: t('rows.host-id'), field: 'payload.winid', editable: 'onAdd',
            render: (rowData) => <DeviceWinid device={rowData.target} />,
            cellStyle: { minWidth: '250px' },
            editComponent: DeviceWinidEditable,
        },
        {
            title: t('rows.label'), field: 'payload.label', editable: 'always',
            cellStyle: { minWidth: '200px' },
            render: rowData => console.log({ rowData }) || <DeviceLabel device={rowData.target} />,
            editComponent: DeviceLabelEditable,
        },
        {
            title: t('rows.subscriptions'), field: 'payload.lines.length', editable: 'never',
            render: rowData => rowData && <DeviceSubscriptionsCount device={rowData.target} />,
        },
        {
            title: t('rows.office-demo'), field: 'payload.office_demo', editable: 'never',
            render: rowData => rowData && <DeviceOfficeDemoCheckbox device={rowData.target} />,
        },
        {
            title: t('rows.updated'), field: 'payload.updated_at', type: 'date', editable: 'never', search: false,
            render: rowData => rowData && <DeviceLastUpdate device={rowData.target} />
        },
    ], []);

    const onAdd = useCallback(async (data) => {
        const { winid, label } = data.payload;
        if (!WINID_REGEX.test(winid)) {
            throw new Error(t('invalid-host-id'));
        }
        return dispatch(sdk.devices.put(auth, customer.target, { winid, label }))
            .then(data => {
                notif.info(t('device-registered'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
                return Promise.reject(e);
            });
    }, [sdk, auth, customer.target]);

    const onUpdate = useCallback(async (data, old) => {
        const { _id, label } = data.payload;
        if (label.length > 100) {
            throw new Error(t('invalid-label-length'));
        }
        if (label === old.payload.label) {
            return;
        }
        return dispatch(sdk.devices.post(auth, _id, customer.target, { label }))
            .then((data) => {
                notif.info(t('device-modified'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
                return Promise.reject(e);
            });
    }, [sdk, auth, customer.target]);

    const onRefresh = useCallback((ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setLoading(true);
        dispatch(sdk.helpers.loadDevices(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
            });;
    }, [sdk, auth, customer.target]);

    const onDelete = useCallback((ev, data) => {
        ev.preventDefault();
        ev.stopPropagation();
        return dispatch(sdk.devices.remove(auth, data.target, customer.target))
            .then((data) => {
                notif.info(t('device-deleted'), { action: (key) => <ActionOk notif={notif} handle={key} /> });
                return Promise.resolve(data);
            })
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> })
                return Promise.reject(e);
            });
    })

    const actions = useMemo(() => [
        {
            enhanced: true,
            icon: '',
            onClick: (ev) => { },
            action: (row) => <SubscribeDeviceButton device={row} />
        },
        {
            enhanced: true,
            icon: '',
            onClick: (ev) => { },
            action: (row) => <DownloadLicenseButton device={row} />
        }
    ], [customer.target]);


    const innerProps = {
        columns,
        data,
        exportFileName: exports && 'devices-list',
        pageSize,
        onAdd: add && onAdd,
        onUpdate: edit && onUpdate,
        onRefresh: refresh && onRefresh,
        onDelete: refresh && onDelete,
        isLoading,
        actions,
        ...more
    }

    return <TableController {...innerProps} />;
}

export function DeviceOverviewTable({ title }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.devices.table' })
    
    return (<DeviceTableController add edit search exports refresh title={title}
        onRowClick={(ev, rowData, togglePanel) => togglePanel()}
        detailPanel={[
            {
                tooltip: t('details-tooltip'),
                render: rowData => (
                    <DetailPanel>
                        <DeviceTablePanel device={rowData} />
                    </DetailPanel>
                ),
            }
        ]}
    />)
}

export function DeviceListTable({ onSelect, pageSize = 5, selected }) {
    return (<DeviceTableController add search refresh title='' pageSize={pageSize} onSelect={onSelect} selected={selected} />)
}

