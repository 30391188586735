import React from 'react';
import { useSessionsByUserHash } from '../../../selector';
import _ from 'lodash';
import CenteredFullScreen from '../../../../common/component/centered-full-screen';
import { Typography } from '@material-ui/core';
import { SessionsOverviewTable } from './table';
import { useTranslation } from 'react-i18next';

export const NoLinkedSessions = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.linked-sessions' })
    return (
        <CenteredFullScreen>
            <Typography>
                {t('no-licenses')}
            </Typography>
        </CenteredFullScreen>
    )
}

export const LinkedSessionsView = ({ sessions, ...props }) => {
    return <SessionsOverviewTable />
}

export default function LinkedSessions({ title, ...props }) {

    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.linked-sessions' })
    title = title || t('title');
    const sessions = useSessionsByUserHash();

    return (
        <LinkedSessionsView {...props} sessions={sessions} />
    )
}