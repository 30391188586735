import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import { Typography } from '@material-ui/core';

export function orderDate(order) {
    return new Date(order.payload.created_at).toLocaleDateString();
}

export function orderTime(order) {
    return new Date(order.payload.created_at).toLocaleTimeString();
}

function OrderCreatedAt({ order }) {
    return (
        <ResourceGetter resource='orders' id={order} render={({ order }) =>
            <WaitingLoaded data={order} render={() =>
                <Typography>{orderDate(order)}</Typography>
            } />
        } />
    )
}

export default OrderCreatedAt;
