import React, { Fragment, useCallback, useEffect, useState } from 'react';
import UserConsentsModal from './user-consents-modal';
import { useSdk } from '../../../lib/licensing-sdk';
import { useAuth0 } from '../../../lib/auth0-wrapper';

const useLoadConsents = () => {
  const [consents, setConsents] = useState([]);
  const sdk = useSdk();
  const auth = useAuth0();

  const reload = useCallback(async () => {
    const c = await sdk.consents.get(auth)
    setConsents(c);
  }, []);

  useEffect(() => {
    reload()
      .catch(e => {
        console.error(e);
      })
  }, []);

  const consent = consents.length > 0 ? consents[0] : null;
  return [consent, reload];
}

export default function UserConsentsController({ children }) {

  const [currentConsent, reload] = useLoadConsents();

  return (
    <Fragment>
      {children}
      <UserConsentsModal reload={reload} consent={currentConsent} />
    </Fragment>
  );
}
