import React from 'react';
import _ from 'lodash';
import { useNonOpenOrdersForCustomerSelector } from '../../../selectors';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { OrderHistoryTable } from './order-history-table';
import { useTranslation } from 'react-i18next';

function OrderHistoryPanel({ push, ...props }) {

    const customer = useCustomer();
    const orders = useNonOpenOrdersForCustomerSelector(customer.target);
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.details.order-history' })
    return (
        <OrderHistoryTable {...props} onClick={push} title={t('title')} orders={orders} />
    )
}

export default OrderHistoryPanel;
