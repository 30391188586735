import React from 'react';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@material-ui/core';
import { AccountBalance as AccountIcon } from '@material-ui/icons';
import UsersSidebarButton from '../../users/component/users-sidebar-button';
import AddressSidebarButton from './account-address-sidebar-button';
import BillingSidebarButton from './account-billing-sidebar-button';
import { makeStyles } from '@material-ui/styles';
import { useIsAccountant, useIsManager } from '../../relations/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function AccountSidebarList({ pushCallback, contains }) {

    const classes = useStyles();
    const { t } = useTranslation();

    const isAccountant = useIsAccountant();
    const isManager = useIsManager();

    if (!(isAccountant || isManager)) return null;

    const handleClick = (route) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        pushCallback(route);
    }

    return (
        <div>
            <ListItem>
                <ListItemIcon><AccountIcon /></ListItemIcon>
                <ListItemText primary={t('app.account.account-sidebar-list.company')} />
            </ListItem>
            <Collapse in timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    {isAccountant &&
                        <AddressSidebarButton className={classes.nested}
                            selected={contains('account/address')} onClick={handleClick('account/address')} />}
                    {isAccountant &&
                        <BillingSidebarButton className={classes.nested}
                            selected={contains('account/billing')} onClick={handleClick('account/billing')} />}
                    {isManager &&
                        <UsersSidebarButton className={classes.nested}
                            selected={contains('account/users')} onClick={handleClick('account/users')} />}
                </List>
            </Collapse>
        </div>
    )

}

export default AccountSidebarList;
