import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useImpersonateSdk, useSdk } from '../../lib/licensing-sdk';
import { useCustomer } from '../customers/component/customer-provider';
import { STATUS_REVOKED, STATUS_STOPPED, STATUS_UNPAID } from '../order-lines/constants';

export function useAvailableSessionsByReferenceForCustomer(customer) {
    const sdk = useSdk();
    const selector = useMemo(() => createSelector(
        sdk.sessions.selectors.getDb,
        sdk.orderlines.selectors.getDb,
        (sessions, lines) => {
            const list = _.chain(sessions)
                .filter(session => session.payload)
                .filter(session => session.payload.created_by === customer)
                .filter(session => session.payload.user === null)
                .filter(session => lines[session.payload.line]?.payload)
                .cloneDeep()
                .each(session => {
                    session.payload.line = lines[session.payload.line]
                })
                .filter(session => ![STATUS_UNPAID, STATUS_REVOKED, STATUS_STOPPED].includes(session.payload.line.payload.status))
                .groupBy(session => session.payload.line.payload.reference)
                .value();
            return list;
        }
    ), [customer]);
    return useSelector(selector);
}

export function makeUserInSessionSelector(sdk) {
    return createSelector(
        sdk.sessions.selectors.makePopulateList(),
        sdk.users.selectors.getDb,
        (sessions, users) => sessions.map(r => {
            if (r && r.payload) {
                r = _.cloneDeep(r);
                r.payload.user = users[r.payload.user];
            }
            return r;
        })
    );
}

export function makeAvailableSessionsList(sdk) {
    return createSelector(
        sdk.sessions.selectors.makePopulateList(),
        (sessions) => {
            return _.chain(sessions)
                .filter(session => session && session.payload)
                .filter(session => session.payload.user === null)
                .cloneDeep()
                .value()
        }
    )
}

export function makeSessionsByUserHash(sdk, customer) {
    return createSelector(
        sdk.sessions.selectors.makePopulateList(),
        (sessions) => _.chain(sessions)
            .filter(session => session && session.payload)
            .filter(session => session.payload.created_by === customer.target)
            .filter(session => session.payload.user !== null)
            .cloneDeep()
            .groupBy('payload.user')
            .map((sessions, user) => {
                return {
                    payload: {
                        user,
                        sessions
                    },
                }
            })
            .value()
    )
}

export function useSessionsByUserHash() {
    const customer = useCustomer();
    const sdk = useImpersonateSdk();
    const sessionsSelector = useMemo(() => makeSessionsByUserHash(sdk, customer), [sdk, customer]);
    const sessions = useSelector(sessionsSelector);
    return sessions;
}

export function makeSessionsByPlanPolicy(sdk) {
    return createSelector(
        (state, sessions) => sessions,
        sdk.orderitems.selectors.getDb,
        (sessions, orderitems) => {
            return _.chain(sessions)
                .filter(session => session && session.payload)
                .each(session => session.payload.orderitem = orderitems[session.payload.order])
                .filter(session => session.payload.orderitem && session.payload.orderitem.payload)
                .groupBy('payload.orderitem.payload.policy')
                .value();
        }
    )
}

export function makeSessionsMissingOrderItem(sdk) {
    return createSelector(
        (state, sessions) => sessions,
        sdk.orderitems.selectors.getDb,
        (sessions, orderitems) => {
            return _.chain(sessions)
                .filter(session => session && session.payload)
                .filter(session => orderitems[session.payload.order] === undefined || !orderitems[session.payload.order].payload)
                .value();
        }
    )
}

