import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import UserEmailWithAvatar from '../../../../users/component/element/user-email-with-avatar';
import { TableController } from '../../../../common/component/table/table-controller';
import DetailPanel from '../../../../common/component/table/detail-panel';
import { useSessionsByUserHash } from '../../../selector';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import { useNotif } from '../../../../common/component/notification';
import ActionOk from '../../../../common/component/notification/action-ok';
import SessionsList from './sessions-list';
import { useCustomer } from '../../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

export function SessionTableController(props) {

    const { add, edit, remove, exports, refresh, ...more } = props;

    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const sessions = useSessionsByUserHash();
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.linked-sessions.headers' });
    const customer = useCustomer();

    const data = useMemo(() => _.cloneDeep(_.compact(sessions)), [sessions]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            dispatch(sdk.helpers.loadSessions(auth, customer.target)),
            dispatch(sdk.helpers.loadRelations(auth, customer.target)),
        ])
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    const columns = useMemo(() => [
        {
            title: t('member'), field: 'payload.user', editable: 'never',
            render: (rowData) => <UserEmailWithAvatar user={rowData.payload.user} />,
        },
        {
            title: t('in-use'), field: 'payload.sessions.length', editable: 'never',
            cellStyle: { minWidth: '250px' },
        },
    ], [sessions]);

    const innerProps = {
        columns,
        data,
        exportFileName: exports && 'session-list',
        pageSize: 8,
        isLoading,
        ...more
    }

    return <TableController {...innerProps} />;
}

export function SessionsOverviewTable(props) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.linked-sessions.table' });
    return (<SessionTableController add edit search exports refresh title={t('title')}
        onRowClick={(ev, rowData, togglePanel) => {
            if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            togglePanel();
        }}
        detailPanel={[
            {
                tooltip: t('tooltip'),
                render: rowData => (
                    <DetailPanel>
                        <SessionsList sessions={rowData.payload.sessions} />
                    </DetailPanel>
                ),
            }
        ]}
    />)
}

export function SessionsListTable({ onSelect, selected }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages.linked-sessions.table' });
    return (<SessionTableController add edit search refresh title={t('title')} onSelect={onSelect} selected={selected} />)
}
