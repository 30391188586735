import React, { useCallback, useState } from 'react';
import { DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { DeviceListTable } from '../../../../devices/component/device-table';
import { useTranslation } from 'react-i18next';
import { useProductName } from '../../../../products/component/element/product-title';
import { getProductDeviceName } from '../../../../products/component/element/product-device-name';

export default function ChooseDevices(props) {

    const { memory, nextStep, onClose } = props;
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.choose-devices' });
    const [targets, setTargets] = useState(memory || []);

    const onNext = useCallback(() => {
        nextStep(targets);
    }, [targets]);

    const onSelect = useCallback((rows) => {
        setTargets(() => rows.map(r => r.target));
    }, []);

    const handleClick = (cb) => (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        cb(ev);
    }

    return (
        <div>
            <DialogContent>
                <DeviceListTable onSelect={onSelect} selected={targets} />
            </DialogContent>
            <DialogActions>
                <Button disabled={false} onClick={handleClick(onClose)}>
                    {t('cancel')}
                </Button>
                <Button disabled={targets.length === 0} onClick={handleClick(onNext)} color='secondary' >
                    {t('next')}
                </Button>
            </DialogActions>
        </div>
    );
}

export function ChooseDevicesLabel({ product }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.choose-devices' });
    const deviceName = getProductDeviceName(product);

    return (
        <Typography>{t('label', { deviceName })}</Typography>
    )
}