import React from 'react';
import BCardMedia from '../../../common/component/card/card-media';
import { ResourceGetter } from '../../../../lib/licensing-sdk/getter';
import WaitingLoaded from '../../../common/component/waiting-loaded';


function ProductMedia({ product, children, ...props }) {

    return (
        <ResourceGetter resource='products' id={product} render={({ product }) =>
            <WaitingLoaded data={product} render={() =>
                <BCardMedia {...props}
                    title={product.payload.description.title || product.payload.name}
                >
                    {children}
                </BCardMedia>
            } />
        } />

    )
}

export default ProductMedia;
