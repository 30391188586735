import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import {
    DialogContent, DialogActions,
    Typography, ListItem, ListItemText, List,
    Divider, Stepper, Step,
    StepLabel, StepContent, ListItemSecondaryAction, CircularProgress
} from '@material-ui/core';
import { useImpersonateSdk } from '../../../../../lib/licensing-sdk';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../../../../lib/auth0-wrapper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ContinuePurchasesButton, ViewCartButton } from '../steps/order-finished';
import { useChangeLocation, useCustomer } from '../../../../customers/component/customer-provider';
import PriceReferenceName from '../../../../prices/component/element/price-reference-name';
import ProductTitle from '../../../../products/component/element/product-title';
import { useTranslation } from 'react-i18next';

function OrderJob({ device, price, count, onProgress, onFinished, onCatch }) {

    const dispatch = useDispatch();
    const sdk = useImpersonateSdk();
    const auth = useAuth0();

    const customer = useCustomer();

    useEffect(() => {

        const job = async () => {
            for (let i = 0; i < count; i++) {
                await dispatch(sdk.orderlines.putForDevices(auth, [device.target], price, customer.target));
                onProgress();
            }
        }
        job().then(onFinished).catch(onCatch);

    }, []);

    return null;
}

function PriceScheduler({ device, price, count, selected, next }) {

    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);

    const onProgress = () => {
        if (progress < count) setProgress(p => p + 1);
    }

    const onFinished = () => {
        setTimeout(next, 500);
        setDone(true)
    }
    const onCatch = (e) => {
        setError(_.get(e, 'response.data.message') || _.get(e, 'message') || e || 'An error occured');
        setDone(true);
        setTimeout(next, 500);
    };

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={<PriceReferenceName display='inline' price={price} />}
                    secondary={`${progress} / ${count}`}
                />
                <ListItemSecondaryAction>
                    {selected && !done && <CircularProgress variant={progress > 0 ? "static" : "indeterminate"} color="secondary"
                        value={progress / count * 100} />}
                    {error && <Typography color='error'>{error}</Typography>}
                    {done && !error && <CheckCircleIcon color='secondary' />}
                </ListItemSecondaryAction>
            </ListItem>
            {selected && <OrderJob device={device} price={price} count={count}
                onCatch={onCatch} onFinished={onFinished} onProgress={onProgress} />}
        </React.Fragment>
    )

}

function ProductScheduler({ device, product, prices, next, ...props }) {

    prices = _.map(prices);
    const [step, setStep] = useState(0);
    const [active, setActive] = useState(false);

    const onNext = () => {
        setStep(step + 1);
        if (step + 1 == prices.length) {
            next();
        }
    }

    useEffect(() => {
        if (!active && props.active) {
            setActive(true);
        }
    }, [props.active]);

    return (
        <Step {...props}>
            <StepLabel {...props}><ProductTitle product={product} /></StepLabel>
            <StepContent {...props} active={active}>
                <List>
                    {_.map(prices, ({ price, count }, index) => {
                        return (
                            <PriceScheduler key={index} device={device} price={price} count={count} next={onNext} selected={step == index} />
                        )
                    })}
                    <Divider />
                </List>
            </StepContent>
        </Step>
    )

}

export default function JobScheduler({ device, memories, activeStep, onClose }) {

    const products = useMemo(() => _.cloneDeep(memories[activeStep - 1]), [memories, activeStep]);

    const [step, setStep] = useState(0);
    const size = _.size(products);
    const onNext = () => setStep(s => s + 1);
    const changeLocation = useChangeLocation();

    return (
        <React.Fragment>
            <DialogContent>
                <Stepper activeStep={step} orientation="vertical">
                    {_.map(products, (prices, product) => (
                        <ProductScheduler key={product} device={device} product={product} prices={prices} next={onNext} />
                    ))}
                </Stepper>
            </DialogContent>
            <DialogActions>
                {step == size && (
                    <React.Fragment>
                        <ContinuePurchasesButton onClose={onClose} />
                        <ViewCartButton changeLocation={changeLocation} />
                    </React.Fragment>
                )}

            </DialogActions>
        </React.Fragment>
    )
}


export function OrderJobsLabel({ }) {
    const { t } = useTranslation('translation', { keyPrefix: 'app.orders.modal.jobs-queue' })
    return (
        <span>
            <Typography display='inline'>
                {t('label')}
            </Typography>
        </span>
    )
}