import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '../../../../lib/auth0-wrapper';
import { useImpersonateSdk } from '../../../../lib/licensing-sdk';
import { useDispatch } from 'react-redux';
import { useNotif } from '../../../common/component/notification';
import ActionOk from '../../../common/component/notification/action-ok';
import CenteredFullScreen from '../../../common/component/centered-full-screen';
import Loader from '../../../common/component/loader';
import LinkedSessions from './linked-sessions';
import AvailableSessions from './available-sessions';
import { useCustomer } from '../../../customers/component/customer-provider';
import { useTranslation } from 'react-i18next';

export const LoadingSessions = ({ }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'app.sessions.pages' });
    return (
        <CenteredFullScreen>
            <Loader message={t('loading')} />
        </CenteredFullScreen>
    )
}

function SessionsPage(props) {

    const auth = useAuth0();
    const sdk = useImpersonateSdk();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    const customer = useCustomer();

    useEffect(() => {
        dispatch(sdk.helpers.loadSessions(auth, customer.target))
            .finally(() => setLoading(false))
            .catch(e => {
                notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
            });
    }, []);

    if (loading) {
        return (
            <LoadingSessions />
        )
    }

    return (
        <Grid item container spacing={2} xs={12} >
            <Grid item xs={12} >
                <AvailableSessions />
            </Grid>
            <Grid item xs={12} >
                <LinkedSessions />
            </Grid>
        </Grid>
    )

}

export default SessionsPage;
