import React, { Fragment } from 'react';
import { TableRow, TableCell, Typography, TableHead } from '@material-ui/core';
import { useViewingAsVayanData } from '../../customers/selectors';
import OrderLineStatus from '../../order-lines/component/element/order-line-status';
import OrderLineReferenceName from '../../order-lines/component/element/order-line-reference-name';
import OrderLinePeriodStart from '../../order-lines/component/element/order-line-period-start';
import OrderLinePeriodEnd from '../../order-lines/component/element/order-line-period-end';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function SubscriptionListHead() {
    const { t } = useTranslation('translation', { keyPrefix: 'app.subscriptions.list.headers' })
    return (
        <TableHead>
            <TableRow>
                <TableCell align="left">
                    <Typography>
                        {t('reference')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography>
                        {t('status')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography>
                        {t('start')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography>
                        {t('end')}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export function SubscriptionActions({ orderline }) {
    const isAdmin = useViewingAsVayanData();

    if (isAdmin) {
        return (
            <Fragment>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
            </Fragment>
        )
    }
}

function SubscriptionRow({ orderline }) {
    const dispatch = useDispatch();

    return (
        <TableRow onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            dispatch(push(`billing/subscriptions/${orderline.target}`))
        }} >
            <TableCell align="left">
                <OrderLineReferenceName line={orderline} />
            </TableCell>
            <TableCell align="center">
                <OrderLineStatus line={orderline} />
            </TableCell>
            <TableCell align="center">
                <OrderLinePeriodStart line={orderline} />
            </TableCell>
            <TableCell align="center">
                <OrderLinePeriodEnd line={orderline} />
            </TableCell>
        </TableRow>
    )
}

export default function SubscriptionListRow({ orderline }) {
    return (
        <SubscriptionRow orderline={orderline} />
    );
}
